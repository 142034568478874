<template>
  <div class="knowledge">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>

    <div class="top-buttons" v-if="userRole == 'admin'">
      <!-- <div class="scenarios">
        <scenarios-menu />
      </div> -->
      <div class="wrapper">
        <v-btn
          color="white"
          style="margin-right: 10px"
          
          @click="showConfirmModal = true"
        >
          Импортировать
        </v-btn>
        <v-btn color="#006FC0" dark @click="saveScenario"> Скачать </v-btn>
        <!-- элемент для скачивания файла -->
        <a id="downloadAnchorElem" style="display: none"></a>
      </div>
    </div>
    <!-- height:${
        wHeight / zoom
      }px -->

    <div
      class="content"
      :style="`transform: scale(${zoom}); width:${wWidth}px; `"
      v-if="cards"
    >
      <div
        class="card-columns"
        :style="cards.length > 0 ? null : 'justify-content: center'"
      >
        <div
          class="card-columns__column"
          v-for="column in columnsCount"
          :id="column + '_column'"
          :key="column"
          :style="`z-index:${columnsCount - column}`"
        >
          <div
            class="card-columns__row"
            :id="column + '_row'"
            v-for="(card, index) in getColumnCards(column)"
            :key="index"
          >
            <!--       :hoveredArrowNextBlockId="hoveredArrowNextBlockId" -->
            <!-- @hover_button_on="highlightArrow($event, 'on')"
              @hover_button_off="highlightArrow($event, 'off')" -->
            <!-- блок карточка -->
            <action-card
              :id="card.id"
              :cardInfo="card"
              :cardIndex="index"
              :zoom="zoom"
              @update="getCards()"
              @deleted="blockDeleted($event)"
              @linked="linkedByArrow($event)"
              @has_next="saveArrow($event)"
              @has_next_or_link="toggleNotification('has_next_or_link')"
            />
          </div>
          <!-- пустые строки для кнопок добавления -->
          <div
            class="card-columns__row empty__row"
            v-for="item in getEmptyRowsLength(column)"
            :key="item + '_btn'"
          >
            <!-- кнопка добавить в текущую колонку -->
            <button @click="newCard(column)" v-if="column !== 1 && userRole == 'admin'" class="btn">
              <v-icon class="btn__icon"> mdi-plus </v-icon
              ><span class="btn__text">Новый блок</span>
            </button>
          </div>
        </div>
        <!-- пустая следующая колонка с кнопками -->
        <div class="card-columns__column" v-if="userRole == 'admin'">
          <div
            class="card-columns__row empty__row"
            v-for="item in getColumnCards(columnsCount).length"
            :key="item"
          >
            <!-- кнопка добавить в следующую колонку -->
            <button @click="newCard(columnsCount + 1)" class="btn">
              <v-icon class="btn__icon"> mdi-plus </v-icon
              ><span class="btn__text">Новый блок</span>
            </button>
          </div>
        </div>
      </div>
      <!-- стрелки -->
      <!-- :viewBox="`${arrow.scrollX} ${arrow.scrollY} ${wWidth} ${wHeight}`" 
       :style="
            `width: ${wWidth}px; height: ${wHeight}px; top: ${arrow.scrollY}px; left: ${arrow.scrollX}px; `
          "-->

      <div class="arrows" v-if="arrows.arrows_info.length > 0">
        <svg
          v-for="(arrow, index) in arrows.arrows_info"
          :key="index + '_arrow'"
          class="arrow"
          :viewBox="`0 0 ${calcSvgWidth(
            wWidth,
            arrow.startX,
            arrow.endX
          )} ${calcSvgHeight(wHeight, arrow.startY, arrow.endY)}`"
          :style="`width: ${calcSvgWidth(
            wWidth,
            arrow.startX,
            arrow.endX
          )}px; height: ${calcSvgHeight(
            wHeight,
            arrow.startY,
            arrow.endY
          )}px; top: 0px; left: 0px; `"
        >
          <defs>
            <marker
              id="global_markerCircle"
              markerWidth="5"
              markerHeight="5"
              refX="3"
              refY="3"
            >
              <circle cx="3" cy="3" r="2" style="stroke: none; fill: #cdd9e3" />
            </marker>
            <!-- <marker
              id="global_arrowhead"
              markerWidth="4"
              markerHeight="8"
              refX="4"
              refY="4"
              orient="auto"
            >
              <use
                stroke-linecap="round"
                xlink:href="#global_arrow-head"
                style="stroke-width: 1px"
                class="use"
                :class="arrow.highlight ? 'highlight' : 'no_highlight'"
              >
                <path
                  class="arrow-path"
                  id="global_arrow-head"
                  d="M 0 1 L 4 4 M 0 7 L 4 4"
                />
              </use>
            </marker> -->
          </defs>
          <!-- <line
            :x1="arrow.startX"
            :y1="arrow.startY"
            :x2="arrow.endX"
            :y2="arrow.endY"
            stroke="#CDD9E3"
            stroke-width="2"
            marker-end="url(#global_arrowhead)"
            marker-start="url(#global_markerCircle)"
          /> -->
          <!--     @mouseenter="arrowMouseOver(arrow.id, arrow.next_block)"
            @mouseleave="arrowMouseOut(arrow.id)"
                      @click="arrowFocuse($event, arrow.id, arrow.next_block)" -->
          <path
            @mouseenter="arrowHoverOn(arrow.id, arrow.next_block)"
            @mouseleave="arrowHoverOff(arrow.id, arrow.next_block)"
            @click="arrowFocuseOn($event, arrow.id, arrow.next_block)"
            :id="arrow.id + '_arrow'"
            ref="arrow_path"
            stroke-linecap="round"
            class="arrow-path"
            fill="transparent"
            stroke="#CDD9E3"
            style="stroke-width: 2px"
            :d="`M ${arrow.startX} ${arrow.startY} 
            C ${arrow.startX + (arrow.forward ? 150 : -150)} ${arrow.startY}  
              ${arrow.endX + (arrow.forward ? -150 : 150)} ${arrow.endY}
             ${arrow.endX}, ${arrow.endY}
             M ${arrow.endX + (arrow.forward ? -7 : 7)}, ${
              arrow.endY + (arrow.forward ? -6 : 6)
            }
             L ${arrow.endX}, ${arrow.endY}
             M ${arrow.endX}, ${arrow.endY} 
             L ${arrow.endX + (arrow.forward ? -7 : 7)}, ${
              arrow.endY + (arrow.forward ? 6 : -6)
            }`"
            marker-start="url(#global_markerCircle)"
          />
        </svg>
      </div>
    </div>

    <!-- пустая следующая колонка с кнопками -->
    <div
      v-else
      class="card-columns__column"
      style="align-items: flex-start; padding-left: 100px; padding-top: 80px"
    >
      <div class="card-columns__row empty__row">
        <!-- кнопка добавить в следующую колонку -->
        <button @click="newCard(0)" class="btn">
          <v-icon class="btn__icon"> mdi-plus </v-icon
          ><span class="btn__text">Новый блок</span>
        </button>
      </div>
    </div>
    <!-- зум -->
    <div class="zoom">
      <v-btn
        color="white"
        block
        width="40"
        height="49"
        depressed
        @click="zoomPlus"
        class="zoom-btn"
      >
        <img src="@/assets/add.svg" alt="plus" />
      </v-btn>
      <div
        class="separator"
        style="border-bottom: 1px solid rgba(28, 28, 28, 0.1); width: 100%"
      ></div>
      <v-btn
        color="white"
        block
        width="40"
        height="49"
        depressed
        @click="zoomMinus"
        class="zoom-btn"
      >
        <img src="@/assets/remove.svg" alt="minus" />
      </v-btn>
    </div>
    <!-- всплывашка снизу экрана с предупреждениями -->
    <notification
      :show="showNotification"
      :text="notificationText"
      @close="showNotification = false"
    />
    <!-- модалка подтверждения загрузки нового сценария -->
    <import-confirm-modal
      :show="showConfirmModal"
      @close="showConfirmModal = false"
      @imported="
        showConfirmModal = false;
        getCards();
      "
    ></import-confirm-modal>
  </div>
</template>

<script>
import axios from "axios";
const ImportConfirmModal = () =>
  import("../components/Knowledge/importConfirmModal.vue");
const ActionCard = () => import("../components/Knowledge/ActionCard.vue");
// import ScenariosMenu from "../components/Knowledge/ScenariosMenu.vue";
const Notification = () => import("../components/Knowledge/notification");
export default {
  components: { ActionCard, Notification, ImportConfirmModal },
  name: "Knowledge",
  data() {
    return {
      loading: false,

      zoom: 1.0,

      showNotification: false,
      notificationText: "",
      cards: [],

      arrows: {
        elements: [],
        arrows_info: [],
        // arrows_info_map: null,
      },

      arrowFocused: false,
      arrowFocusedElement: null,
      arrowFocusedInfo: {
        id: "",
        next_block: "",
      },
      // модалка подтверждения загрузки нового сценария
      showConfirmModal: false,
    };
  },
  methods: {
    zoomPlus() {
      if (Math.round(this.zoom * 100) / 100 >= 2) return;
      this.zoom += 0.1;
      setTimeout(() => {
        this.drawArrows();
      }, 500);
    },
    zoomMinus() {
      if (Math.round(this.zoom * 100) / 100 <= 0.1) return;
      this.zoom -= 0.1;
      setTimeout(() => {
        this.drawArrows();
      }, 500);
    },
    toggleNotification(trigger) {
      switch (trigger) {
        case "has_next_or_link": {
          this.notificationText = `Кнопка может иметь либо только ссылку, либо только связь с другим блоком`;
          this.showNotification = true;
          break;
        }
        case "cross_arrows": {
          this.notificationText = `Связи не должны пересекаться`;
          this.showNotification = true;
          break;
        }
        default:
          break;
      }
    },
    getCards() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get("blocks/")
          .then((resp) => {
            // let cardsWithLinkedField = addLinkedField(resp.data)
            this.cards = resp.data;
            this.loading = false;
            // this.$forceUpdate();
            resolve(resp);
          })
          .then(() => {
            setTimeout(() => {
              this.drawArrows();
            }, 100);
          })
          .catch((err) => {
            this.loading = true;
            reject(err);
            console.log(err);
          });
      });
    },
    newCard(column) {
      let block = {
        tag: "",
        text: "",
        buttons: [],
        inline_buttons: [],
        position: {
          col: this.cards.length <= 0 ? column - 2 : column - 1,
          row: this.getColumnCards(column).length,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .post("blocks/", block)
          .then((resp) => {
            this.getCards();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    // карточки для определенной колонки
    getColumnCards(column) {
      let cardColumn = [];
      this.cards.forEach((card) => {
        if (card.position.col == column - 1) {
          cardColumn.push(card);
        }
      });

      return cardColumn;
      // TODO: пока сортировка отключена, тк при удалении блоков позиции не обновляются
      // return cardColumn.sort(function(a, b) {
      //   if (a.position.row > b.position.row) {
      //     return 1;
      //   }
      //   if (a.position.row < b.position.row) {
      //     return -1;
      //   }
      // });
    },
    getEmptyRowsLength(column) {
      let prev = this.getColumnCards(column - 1).length;
      let curr = this.getColumnCards(column).length;
      if (prev <= curr) {
        return 1;
      } else {
        return prev - curr;
      }
    },
    saveArrow(data) {
      let income_arrow = {
        id: data.id,
        circleX: data.circleX + window.pageXOffset || document.body.scrollLeft,
        circleY: data.circleY + window.pageYOffset || document.body.scrollTop,
        circle_ref: data.circle_ref,
        next_block: data.next_block,
        scrollY: window.pageYOffset || document.body.scrollTop,
        scrollX: window.pageXOffset || document.body.scrollLeft,
        zoom: this.zoom,
      };
      if (income_arrow.next_block === null) {
        // если было удаление и приходит нулевой блок привязки
        this.arrows.elements.forEach((el, index) => {
          if (el.id === income_arrow.id) {
            this.arrows.elements.splice(index, 1);
          }
        });
        // this.arrows.elements.delete(income_arrow.id);
      } else {
        if (this.arrows.elements.length > 0) {
          let index = this.arrows.elements.findIndex((el) => {
            if (el.id === income_arrow.id) return true;
          });
          if (index >= 0) {
            this.arrows.elements[index] = income_arrow;
          } else {
            this.arrows.elements.push(income_arrow);
          }
        } else {
          this.arrows.elements.push(income_arrow);
        }
        // let index = income_arrow.id;
        // if (this.arrows.elements !== null) {
        //   this.arrows.elements.set(index, income_arrow);
        // } else {
        //   this.arrows.elements = new Map();
        //   this.arrows.elements.set(index, income_arrow);
        // }
      }
    },
    drawArrows() {
      this.arrows.arrows_info = [];
      // this.arrows.arrows_info_map = new Map();
      if (this.arrows.elements == null) return;
      // удалить стрелки без блока
      this.arrows.elements.forEach((el, index) => {
        if (!document.getElementById(`${el.next_block}`)) {
          this.arrows.elements.splice(index, 1);
          // this.arrows.elements.delete(index);
        }
      });
      this.arrows.elements.forEach((el) => {
        // определить направление стрелки (вперед/назад) и в завис. от этого вычислить куда вести стрелку
        let startX,
          startY,
          endX,
          endY,
          isForward = false;
        if (
          el.circle_ref.getBoundingClientRect().x <
          document.getElementById(`${el.next_block}`).getBoundingClientRect().x
        ) {
          startX =
            (el.circle_ref.getBoundingClientRect().x + 7 + window.pageXOffset ||
              document.body.scrollLeft) / this.zoom;
          startY =
            (el.circle_ref.getBoundingClientRect().y -
              63 +
              window.pageYOffset || document.body.scrollTop) / this.zoom;
          // в зависимости от зума прибавляется погрешность уменьшения/увеличения пикселей
          endX =
            (document.getElementById(`${el.next_block}`).getBoundingClientRect()
              .x -
              (this.zoom >= 1 ? 30 : 10) +
              window.pageXOffset || document.body.scrollLeft) / this.zoom;
          endY =
            (document.getElementById(`${el.next_block}`).getBoundingClientRect()
              .y +
              (this.zoom >= 1 ? 100 : 0) +
              window.pageYOffset || document.body.scrollTop) / this.zoom;
          isForward = true;
        } else {
          // если стрелка назад, добавить ширину блока для конца, вычесть для начала стрелки
          startX =
            (el.circle_ref.getBoundingClientRect().x +
              60 -
              document.getElementById(`${el.next_block}`).offsetWidth *
                this.zoom +
              window.pageXOffset || document.body.scrollLeft) / this.zoom;
          startY =
            (el.circle_ref.getBoundingClientRect().y -
              63 +
              window.pageYOffset || document.body.scrollTop) / this.zoom;
          endX =
            (document.getElementById(`${el.next_block}`).getBoundingClientRect()
              .x +
              (this.zoom >= 1 ? 30 : 10) +
              document.getElementById(`${el.next_block}`).offsetWidth *
                this.zoom +
              window.pageXOffset || document.body.scrollLeft) / this.zoom;
          endY =
            (document.getElementById(`${el.next_block}`).getBoundingClientRect()
              .y +
              (this.zoom >= 1 ? 100 : 0) +
              window.pageYOffset || document.body.scrollTop) / this.zoom;
        }
        let arrow_data = {
          id: el.id,
          next_block: el.next_block,
          startX: startX,
          startY: startY,
          endX: endX,
          endY: endY,
          scrollY: el.scrollX,
          scrollX: el.scrollY,
          zoom: el.zoom,
          // направление стрелки
          forward: isForward,
        };
        this.arrows.arrows_info.push(arrow_data);
        // отдельно те же стрелки в виде map для удобного поиска
        // let map_index = el.id;
        // let map_data = {
        //   ...arrow_data,
        //   index: this.arrows.arrows_info.length - 1,
        // };
        // this.arrows.arrows_info_map.set(map_index, map_data);
      });
    },
    linkedByArrow(nextBlock_id) {
      let initiator = this.$store.getters["arrow/getInitiatorInfo"];
      let urlPath =
        initiator.type == "block"
          ? `blocks/${initiator.block_id}/link`
          : initiator.type == "feedback"
          ? `blocks/${initiator.block_id}/post_data/link`
          : `blocks/${initiator.block_id}/${initiator.type}s/${initiator.element_id}/link`;
      if (!this.checkBlockOnCrossArrows(initiator, nextBlock_id)) {
        return new Promise((resolve, reject) => {
          axios
            .post(urlPath, {
              next_block: nextBlock_id,
            })
            .then((resp) => {
              this.getCards();
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
              console.log(err);
            });
        });
      } else {
        this.toggleNotification("cross_arrows");
      }
    },
    // проверка блока на скрещивание стрелок при создании стрелки в этом блоке
    checkBlockOnCrossArrows(initiator, nextBlock_id) {
      let potentialBlock;
      let buttons = [];
      // берем все блоки
      let checkingBlockCol = initiator.col_position;
      let checkingBlocks = this.cards.filter((card) => {
        if (card.position.col === checkingBlockCol) return true;
      });
      checkingBlocks.forEach((block) => {
        // собираем кнопки для проверки
        buttons.push(
          ...block.buttons,
          ...block.inline_buttons,
          ...block.phrases_groups
        );
      });

      // ищем потенциальный блок для привязки
      potentialBlock = this.cards.find((card) => {
        if (card.id === nextBlock_id) return true;
      });

      let potentialColumnCards = this.getColumnCards(
        potentialBlock.position.col + 1
      );

      let potentialBlock_index = potentialColumnCards.findIndex((card) => {
        if (card.id === potentialBlock.id) return true;
      });
      // ищем кнопку инициатор
      let potentialElementIndex = buttons.findIndex((btn) => {
        if (btn.id === initiator.element_id) return true;
      });
      // инициатор
      /* block_id: "",
        element_id: "",
        type: "",
        col_position: "", */
      let arrowsWillCross = false;

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id === initiator.element_id) continue;
        // след кнопка
        let nextBtn = buttons[i];
        let nextBtn_nextElementIndex = buttons.findIndex((btn) => {
          if (btn.id === nextBtn.id) return true;
        });

        // находим привязанный блок кнопки из цикла
        let nextBtn_nextBlock_index =
          nextBtn.next_block === null
            ? null
            : potentialColumnCards.findIndex((card) => {
                if (card.id === nextBtn.next_block) return true;
              });

        if (nextBtn_nextBlock_index === null) continue;
        // console.log(
        //   potentialElementIndex , nextBtn_nextElementIndex
        //     ,potentialBlock_index ,nextBtn_nextBlock_index
        //     ,potentialBlock_index ,nextBtn_nextBlock_index
        // );
        // сравниваем позиции след блоков у кнопки инициатора и проверяемой
        if (
          potentialElementIndex > nextBtn_nextElementIndex
            ? potentialBlock_index < nextBtn_nextBlock_index
            : potentialBlock_index > nextBtn_nextBlock_index
        ) {
          arrowsWillCross = true;
        }
      }
      // обратные стрелки могут пересекаться
      if (initiator.backward) {
        arrowsWillCross = false;
      }
      return arrowsWillCross;
    },
    // рассчет ширины Svg для стрелки на основе её положения и направления
    calcSvgWidth(wWidth, start, end) {
      if (start < end) {
        return wWidth < end + 10
          ? end + 10
          : wWidth < start + 10
          ? start + 10
          : wWidth;
      } else {
        return wWidth < start + 10
          ? start + 10
          : wWidth < end + 10
          ? end + 10
          : wWidth;
      }
    },
    // рассчет длины Svg для стрелки на основе её положения и направления
    calcSvgHeight(wHeight, start, end) {
      if (start < end) {
        return wHeight < end + 10
          ? end + 10
          : wHeight < start + 10
          ? start + 10
          : wHeight;
      } else {
        return wHeight < start + 10
          ? start + 10
          : wHeight < end + 10
          ? end + 10
          : wHeight;
      }
    },
    // подчищаем стрелки за удаленным блоком
    blockDeleted(block_id) {
      let arrows = [...this.arrows.elements];
      arrows.forEach((el, index) => {
        if (el.next_block === block_id) {
          // this.arrows.elements.splice(index, 1);
          this.arrows.elements.delete(index);
        }
      });
      this.getCards();
    },

    // меняем блоки местами
    // swapBlocks(firstBlock, secondBlock) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .patch(`blocks/${firstBlock.id}`, {
    //         tag: firstBlock.tag,
    //         text: firstBlock.text,
    //         buttons: firstBlock.buttons,
    //         inline_buttons: firstBlock.inline_buttons,
    //         position: secondBlock.position,
    //         image_url: firstBlock.image_url,
    //       })
    //       .then(() => {
    //         axios.patch(`blocks/${secondBlock.id}`, {
    //           tag: secondBlock.tag,
    //           text: secondBlock.text,
    //           buttons: secondBlock.buttons,
    //           inline_buttons: secondBlock.inline_buttons,
    //           position: firstBlock.position,
    //           image_url: secondBlock.image_url,
    //         });
    //       })
    //       .then((resp) => {
    //         this.getCards();
    //         resolve(resp);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //         console.log(err);
    //       });
    //   });
    // },
    // включение/отключение классов подсветки стрелок и блоков
    highlightElements(arrow_id, next_block_id, mode, className) {
      if (mode == "on") {
        document
          .getElementById(next_block_id + "_card")
          .classList.add(className);
        document.getElementById(arrow_id + "_arrow").classList.add(className);
        document.getElementById(arrow_id + "_button")
          ? document
              .getElementById(arrow_id + "_button")
              .classList.add(className)
          : document
              .getElementById(arrow_id + "_card")
              .classList.add(className);
      } else {
        document
          .getElementById(next_block_id + "_card")
          .classList.remove(className);
        document
          .getElementById(arrow_id + "_arrow")
          .classList.remove(className);
        document.getElementById(arrow_id + "_button")
          ? document
              .getElementById(arrow_id + "_button")
              .classList.remove(className)
          : document
              .getElementById(arrow_id + "_card")
              .classList.remove(className);
      }
    },
    // при ховере стрелки, включаем выделение соотвт желементов
    arrowHoverOn(arrow_id, next_block_id) {
      if (arrow_id == "" || next_block_id == "") return;
      this.highlightElements(arrow_id, next_block_id, "on", "highlight");
    },
    // снимаем выделение вне стрелки
    arrowHoverOff(arrow_id, next_block_id) {
      this.highlightElements(arrow_id, next_block_id, "off", "highlight");
    },
    // снимаем ховер эффект с элементов стрелки
    arrowFocusOff(arrow_id, next_block_id) {
      if (arrow_id == "" || next_block_id == "") return;
      this.highlightElements(arrow_id, next_block_id, "off", "focus");
    },
    // фокус на стрелку
    arrowFocuseOn(e, arrow_id, next_block) {
      if (this.arrowFocused) {
        this.arrowFocused = false;
      }
      this.arrowFocusOff(
        this.arrowFocusedInfo.id,
        this.arrowFocusedInfo.next_block
      );
      this.arrowFocusedElement = e.target;
      this.arrowFocusedInfo = {
        id: arrow_id,
        next_block: next_block,
      };
      this.highlightElements(arrow_id, next_block, "on", "focus");
      this.arrowFocused = true;
    },
    documentClick(e) {
      if (e.target !== this.arrowFocusedElement) {
        this.arrowFocused = false;
        this.arrowFocusOff(
          this.arrowFocusedInfo.id,
          this.arrowFocusedInfo.next_block
        );
        this.arrowFocusedInfo = {
          id: "",
          next_block: "",
        };
      }
    },
    // глубока заморозка cards, чтобы убрать все watcher и уменьшит лаги, но не сработало
    // deepFreezeCards(obj) {
    //   obj.forEach((item) => {
    //     // Получаем имена свойств из объекта obj
    //     let propNames = Object.getOwnPropertyNames(item);

    //     // Замораживаем свойства для заморозки самого объекта
    //     propNames.forEach((name) => {
    //       let prop = item[name];
    //       // Заморозка свойства prop, если оно массив
    //       if (Array.isArray(prop) && prop !== null) this.deepFreezeCards(prop);
    //       if (typeof prop == "object" && prop !== null) Object.freeze(prop);
    //     });

    //     // Заморозить сам объект item
    //     return Object.freeze(item);
    //   });
    //   // Заморозить сам объект obj (ничего не произойдёт, если он уже заморожен)
    //   return Object.freeze(obj);
    // },

    // скачать текущий сценарий в формате json
    saveScenario() {
      return new Promise((resolve, reject) => {
        axios
          .get("blocks/export")
          .then((resp) => {
            let dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(resp.data));
            let dlAnchorElem = document.getElementById("downloadAnchorElem");
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", "scenario.json");
            dlAnchorElem.click();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  computed: {
    userRole() {
     return this.$store.getters["authorization/getRole"];
    },
    wWidth() {
      // return this.scrollWidth / this.zoom;
      return document.body.scrollWidth;
    },
    wHeight() {
      // return window.innerHeight - 80;
      return document.body.scrollHeight;
    },
    columnsCount() {
      let prevCol = 0;
      this.cards.forEach((card) => {
        if (card.position.col > prevCol) {
          prevCol = card.position.col;
        }
      });
      return prevCol + 1;
    },
    hoveredArrowInfo() {
      return this.$store.getters["arrow/getHoveredArrow"];
    },
    // значения для процентов .content transform translate
    // zoomTranslate(){
    //   if(this.zoom  == 1) return 0;
    //   let zoomChangesCount =  Math.abs(1 - this.zoom) / 0.1;
    //   let oneChangePersentValue = 5
    //   if(this.zoom < 1){
    //     return zoomChangesCount * (oneChangePersentValue)
    //   }else{
    //     return zoomChangesCount * -(oneChangePersentValue)
    //   }
    // }
  },
  watch: {
    "arrows.elements": function () {
      if (this.arrows.elements.length > 0) {
        this.drawArrows();
      } else {
        this.arrows.arrows_info = [];
      }
    },
  },
  created() {
    this.getCards();
    document.getElementsByTagName("html")[0].classList.add("overflow");
  },
  mounted() {
    document.addEventListener("click", this.documentClick);
    // при обновлении страницы или ее первоначальной загрузке стрелки, которые уходят далеко вниз,
    //  почему-то не дотягиваются до своего блока, поэтому тут такой костыль небольшой
    setTimeout(() => {
      this.drawArrows();
    }, 100);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.knowledge {
  height: 100%;
  width: 100%;
  position: relative;
  // overflow: auto;
}
.content {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 80px 50px;
  transform-origin: 0% 0% 0px;
  transition: transform 0.5s;
  pointer-events: none;
}
.card-columns {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  &__column {
    padding-right: 254px;
    z-index: 15;
    display: flex;
    align-items: center;
    flex-direction: column;
    // при поднятии стрелки, чтобы стрелка была поверх остальных карточек
    &.onTop {
      z-index: 200 !important;
    }
  }
  &__row {
    z-index: 15;
    min-height: 700px;
    // при поднятии стрелки, чтобы стрелка была поверх остальных карточек
    &.onTop {
      z-index: 200 !important;
    }
    &.empty__row {
      height: 270px;
      display: flex;
      align-items: center;
      .btn {
        pointer-events: all;
        width: 146px;
        height: 36px;
        align-self: center;
        margin-top: 20px;
        outline: none;
        background: transparent;
        opacity: 0.3;
        border: 1px solid #1c1c1c;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #1c1c1c;
        transition: all 0.3s ease;
        &__icon {
          color: inherit;
        }
        &:hover {
          color: #006fc0;
          opacity: 1;
          border: 1px solid #006fc0;
        }
      }
    }
  }
  &__row:not(:last-child) {
    margin-bottom: 38px;
  }
}
.zoom {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 98px;
  border-radius: 5px;
  position: fixed;
  top: 40%;
  right: 40px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: white;
  &-btn {
    &.v-btn > .v-btn__content .v-icon {
      color: #1c1c1c;
      opacity: 0.6;
    }
  }
}
.top-buttons {
  position: fixed;
  top: 90px;
  z-index: 20;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
  .wrapper {
    display: flex;
    flex-direction: row;
    pointer-events: all;
  }
  .scenarios {
    pointer-events: all;
  }
}
.arrows {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  .arrow {
    position: fixed;
    pointer-events: none;
    #global_arrowhead {
      stroke: currentColor;
      // .use.no_highlight {
      //   color: #cdd9e3;
      // }
      // .use.highlight {
      //   color: #006fc0;
      // }
    }
  }
}
.arrow-path {
  pointer-events: all;
  transition: stroke 0.3s ease-in-out;
  cursor: pointer;
  &.highlight {
    stroke: #006fc0;
  }
  &.focus {
    stroke: #006fc0;
  }
  // &:hover {
  //   stroke: #006fc0;
  // }
}
</style>
